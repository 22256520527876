<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="headermunt">
			<el-breadcrumb-item :to="{ path: 'budgetProgramme' }">方案管理</el-breadcrumb-item>
			<el-breadcrumb-item>新增方案</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="formdatas">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="方案名称" prop="poName">
					<el-input v-model="ruleForm.poName"></el-input>
				</el-form-item>
				<el-form-item label="方案简介" prop="poContext">
					<el-input type="textarea" :rows="10" v-model="ruleForm.poContext"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<div class="add">
			<el-table border :data="tableData" style="width: 100%" @selection-change="programmeselection">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column type="index" label="序号" width="50">
				</el-table-column>
				<el-table-column type="expand">
					<template slot-scope="props">
						<div>是否订做：{{ props.row.eqCustomMade }}</div>
						<div>发票、税：{{ props.row.eqInvoice }}</div>
						<div>是否安装/安装费：{{ props.row.eqInstall }}</div>
						<div v-if="props.row.eqCustom" v-for="(item,index) in JSON.parse(props.row.eqCustom)">
							{{item.name}}：{{ item.value }}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="类型" prop="shixunName">
				</el-table-column>
				<el-table-column label="名称" prop="eqName">
				</el-table-column>
				<el-table-column label="规格/型号" prop="eqModel">
				</el-table-column>
				<el-table-column label="厂家信息" prop="eqManufacturer">
				</el-table-column>
				<el-table-column label="购买途径" prop="eqPurchaseRoute">
				</el-table-column>
				<el-table-column label="数量" width="100">
					<template slot-scope="props">
						<el-input @input="inputvaluechange" :min="0" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" type="number" v-model="props.row.number">
						</el-input>
					</template>
				</el-table-column>
				<el-table-column width="150">
					<template slot="header" slot-scope="scope">
						倍率
						<template v-if="magnificationtype">
							<i @click="inputkeyword ='',magnificationdialog = true" class="aleft el-icon-edit"></i>
						</template>
					</template>
					<template slot-scope="props">
						<el-input @input="inputvaluechange" type="number" :max="100" placeholder="请输入数"
							v-model="props.row.filed1">
							<template slot="append">%</template>
						</el-input>
					</template>
				</el-table-column>
				<el-table-column label="售价(元)">
					<template slot-scope="props">
						{{props.row.filed1>0?parseInt(props.row.eqOriginalPrice)+((parseInt(props.row.filed1)/100)*parseInt(props.row.eqOriginalPrice)):parseInt(props.row.eqOriginalPrice)-(Math.abs(parseInt(props.row.filed1))/100*parseInt(props.row.eqOriginalPrice))}}

					</template>
				</el-table-column>
				<el-table-column width="180" label="总价(元)">
					<template slot-scope="props">
						{{props.row.filed1>0?(parseInt(props.row.eqOriginalPrice)+((parseInt(props.row.filed1)/100)*parseInt(props.row.eqOriginalPrice)))*parseInt(props.row.number):(parseInt(props.row.eqOriginalPrice)-(Math.abs(parseInt(props.row.filed1))/100*parseInt(props.row.eqOriginalPrice)))*parseInt(props.row.number)}}
					</template>
				</el-table-column>
				<el-table-column label="操作" width="80">
					<template slot-scope="props">
						<el-button @click="deletetabledata(props)" size="mini" type="primary" icon="el-icon-delete">
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="numbers" v-if="tableData&&tableData.length>0">
				合计金额：{{tableDatanumber}}万元
				<!-- <el-switch @change="switchchange" v-model="unitvalue" active-text="元" inactive-text="万元">
				</el-switch> -->
			</div>
			<el-button class="addssa" type="primary" @click="opendialog">添加设备</el-button>
		</div>
		<div class="formsubmit">
			<el-button type="primary" @click="datadetermine">提交</el-button>
			<el-button @click="$router.go(-1)">取消</el-button>
		</div>
		<el-dialog :close-on-click-modal="false" title="设备列表" :visible.sync="dialogVisible" width="70%" class="dialogs">
			<el-input placeholder="请输入设备名称" v-model="keywordinput" class="input-with-select">
				<el-select slot="prepend" v-model="FormshixunId" placeholder="请选择设备类型" @change="shixunIdchange">
					<el-option v-for="(item,index) in equipmentTypeList" :label="item.shixunName" :value="item.id"
						:key="index">
					</el-option>
				</el-select>
				<el-button slot="append" icon="el-icon-search"></el-button>
			</el-input>
			<el-table border ref="multipleTable" :data="choicetableData" style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" :selectable="checkSelectable">
				</el-table-column>
				<el-table-column type="index" label="序号" width="50">
				</el-table-column>
				<el-table-column type="expand">
					<template slot-scope="props">
						<div>是否订做：{{ props.row.eqCustomMade }}</div>
						<div>发票、税：{{ props.row.eqInvoice }}</div>
						<div>是否安装/安装费：{{ props.row.eqInstall }}</div>
						<div v-if="props.row.eqCustom" v-for="(item,index) in JSON.parse(props.row.eqCustom)">
							{{item.name}}:{{ item.value }}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="类型" prop="shixunName">
				</el-table-column>
				<el-table-column label="名称" prop="eqName">
				</el-table-column>
				<el-table-column label="规格/型号" prop="eqModel">
				</el-table-column>
				<el-table-column label="厂家信息" prop="eqManufacturer">
				</el-table-column>
				<el-table-column label="购买途径" prop="eqPurchaseRoute">
				</el-table-column>
				<!-- 
				<el-table-column label="数量">
					<template slot-scope="props">
						<el-input onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" :step="1" :min="0" type="number"
							v-model="props.row.number"></el-input>
					</template>
				</el-table-column> -->
				<el-table-column label="原始单价" prop="eqOriginalPrice" width="120">
				</el-table-column>
			</el-table>
			<div class="pagination" v-if="totalnumber>10">
				<el-pagination
				:current-page="pagenum"
				@current-change="paginationchange"
				  background
				  layout="prev, pager, next"
				  :total="totalnumber">
				</el-pagination>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="magnificationdialog">
			<el-input v-model="inputkeyword" placeholder="请输入" type="number" :max="100"><template
					slot="append">%</template></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="magnificationdialog = false">取 消</el-button>
				<el-button type="primary" @click="magnificationSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['reload'],
		data() {
			return {
				totalnumber:"",
				pagenum:1,
				userId: sessionStorage.getItem('userId'),
				roleId: sessionStorage.getItem('roleId'),
				FormshixunId: 1,
				times: '',
				keywordinput: "",
				unitvalue: true,
				dialogVisible: false,
				choicetableData: [],
				tableData: [],
				copytableData: [],
				ruleForm: {
					poName: '',
					poContext: '',
				},
				rules: {
					poName: [{
							required: true,
							message: '请输入方案名称',
							trigger: 'blur'
						}
						// ,
						// {
						// 	min: 3,
						// 	max: 5,
						// 	message: '长度在 3 到 5 个字符',
						// 	trigger: 'blur'
						// }
					],
					poContext: [{
						required: true,
						message: '请输入方案简介',
						trigger: 'blur'
					}]
				},
				equipmentTypeList: '',
				inputkeyword: '',
				magnificationtype: false,
				magnificationdialog: false,
				clickprogrammedatas: [],
				tableDatanumber: ''
			};
		},
		created() {
			this.getEquipmentTypeList()
		},
		mounted() {
			// this.times = setInterval(()=>{
			// 	console.log("12")
			// },300000);
			// this.$once('hook:beforeDestroy', () => {           
			//     clearInterval(this.times);                                   
			// })
			if(this.$route.query.id){
				this.querySortPlan()
			}
		},
		methods: {
			//分页
			paginationchange(val){
				this.pagenum = val
				this.getEquipmentList(this.FormshixunId)
			},
			async querySortPlan(){
				await this.$post('querySortPlanById',{
					id:this.$route.query.id
				}).then((res)=>{
					res.equipmentList.forEach((e,m)=>{
						e.filed1 = 0
						e.number = e.equipmentNum
						e.isModify = '是'
					})
					if(res.unit == 0){
						this.unitvalue = true
					}else{
						this.unitvalue = false
					}
					this.tableData = res.equipmentList
					this.ruleForm.poName = res.equName
					this.ruleForm.poContext = res.equContent
					this.tableDatanumber = res.equPrice
				})
			},
			inputvaluechange(val) {
				this.numberover()
			},
			switchchange(val) {
				let ghj = 0
				let bnm = ''
				bnm = this.tableDatanumber / 10000
				let ghh = bnm.toString().split('.')
				if (ghh.length > 1) {
					let yui = ''
					if (ghh[0].length > 4) {
						let uio = ghh[0].split('')
						let plm = ghh[0].length - 1
						uio.splice(plm, 1, "0")
						uio.forEach((r, t) => {
							yui += r.toString()
						})
						this.tableDatanumber = yui
					} else {
						this.tableDatanumber = bnm
					}
				} else {
					this.tableDatanumber = bnm
				}
			},
			magnificationSubmit() {
				this.clickprogrammedatas.forEach((e, m) => {
					this.tableData.forEach((z, x) => {
						if (e.equipmentId == z.equipmentId) {
							z.filed1 = this.inputkeyword
						}
					})
				})
				this.magnificationdialog = false
			},
			programmeselection(val) {
				this.clickprogrammedatas = val
				if (val && val.length >= 2) {
					this.magnificationtype = true
				} else {
					this.magnificationtype = false
				}
			},
			shixunIdchange(val) {
				this.pagenum = 1
				this.totalnumber = 0
				this.getEquipmentList(val)
			},
			async getEquipmentList(id) {
				this.choicetableData = []
				await this.$post('equipment/getEquipmentList', {
					pagenum: this.pagenum,
					pagesize: 10,
					shixunId: id,
					userId: this.userId,
					roleId: this.roleId
				}).then((res) => {
					let datas = res.list
					this.totalnumber = res.total
					if (datas && datas.length > 0 && this.tableData && this.tableData.length > 0) {
						this.tableData.forEach((q, w) => {
							datas.forEach((e, m) => {
								e.number = 1
								if (e.id == q.id) {
									e.isModify = '否'
								} else {
									e.isModify = '是'
								}
							})
						})

					} else {
						datas.forEach((e, m) => {
							e.number = 1
							e.isModify = '是'
						})
					}
					this.choicetableData = datas
				})
				this.$set(this.choicetableData)
			},
			async getEquipmentTypeList() {
				await this.$post('equipment/getEquipmentTypeList').then((res) => {
					this.equipmentTypeList = res
				})
				this.getEquipmentList(this.equipmentTypeList[0].id)
			},
			numberover(val) {
				let ghj = 0
				if (this.tableData && this.tableData.length > 0) {
					this.tableData.forEach((e, m) => {
						e.filed1 > 0 ? e.numberovers = (parseInt(e.eqOriginalPrice) + ((parseInt(e.filed1) / 100) *
							parseInt(e.eqOriginalPrice))) * parseInt(e.number) : e.numberovers = (parseInt(e
							.eqOriginalPrice) - (Math.abs(parseInt(e.filed1)) / 100 * parseInt(e
							.eqOriginalPrice))) * parseInt(e.number)
						ghj += e.numberovers
					})
					ghj = ghj/10000
					let ghh = ghj.toString().split('.')
					if (ghh.length > 1) {
						let bnm = ''
						if (ghh[0].length > 4) {
							let uio = ghh[0].split('')
							let plm = ghh[0].length - 1
							uio.splice(plm, 1, "0")
							uio.forEach((r, t) => {
								bnm += r.toString()
							})
							ghj = bnm
						}
					}
				} else {

				}
				this.tableDatanumber = ghj
			},
			submitForm() {
				if (this.copytableData && this.copytableData.length > 0) {
					this.copytableData.forEach((e, m) => {
						e.filed1 = 0
						this.tableData.push(e)
					})
					this.numberover()
					this.dialogVisible = false
				} else {
					this.$message({
						message: '请先选择设备',
						type: 'warning'
					});
				}
				console.log(this.tableData)
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			checkSelectable(row) {
				return row.isModify == '是'
			},
			handleSelectionChange(val) {
				this.copytableData = []
				if (val && val.length > 0) {
					this.copytableData = val
				}
			},
			opendialog() {
				let that = this
				that.dialogVisible = true
				that.copytableData = []
				if (that.tableData && that.tableData.length > 0) {
					that.tableData.forEach((q, w) => {
						console.log(q.id)
						that.choicetableData.forEach((a, s) => {
							console.log(a.id)
							if (a.id == q.id) {
								a.isModify = '否'
							}
						})
					})
				}
				that.$nextTick(() => {
					that.$refs.multipleTable.clearSelection()
				})
			},
			datadetermine() {
				let that = this
				let jsonStrs = []
				let fordatas = that.tableData
				let totalPrices = 0
				that.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						if (fordatas && fordatas.length > 0) {
							for (let i in fordatas) {
								if (fordatas[i]) {
									if (fordatas[i].number < 1) {
										that.$message({
											message: fordatas[i].eqName + '数量值格式不对（数量值为大于1的数字）',
											type: 'warning'
										});
										return false
									}
									fordatas[i].filed1>0?fordatas[i].dangenumber = (parseInt(fordatas[i].eqOriginalPrice)+((parseInt(fordatas[i].filed1)/100)*parseInt(fordatas[i].eqOriginalPrice)))*parseInt(fordatas[i].number):fordatas[i].dangenumber = (parseInt(fordatas[i].eqOriginalPrice)-(Math.abs(parseInt(fordatas[i].filed1))/100*parseInt(fordatas[i].eqOriginalPrice)))*parseInt(fordatas[i].number)
									// totalPrices += fordatas[i].number * fordatas[i].eqOriginalPrice
									jsonStrs.push(fordatas[i].id+'#'+fordatas[i].number+'#'+fordatas[i].dangenumber)
								}
							}
							let maxnumber = 0
							let minnumber = 0
							let types = this.$route.query.type
							types == 1 ? maxnumber = 100 :types == 2 ? maxnumber = 500 : ''
							types == 1 ? minnumber = 0 :types == 2 ? minnumber = 100 : minnumber = 500
							if(types!=3&&that.tableDatanumber<minnumber){
								that.$message({
									message:'方案总价小于价格区间最小值'+minnumber+'万元',
									type: 'warning'
								});
								return false
							}
							if(types!=3&&that.tableDatanumber>maxnumber){
								that.$message({
									message:'方案总价大于价格区间最大值'+maxnumber+'万元',
									type: 'warning'
								});
								return false
							}
							if(types==3&&that.tableDatanumber<minnumber){
								that.$message({
									message:'方案总价小于价格区间最小值'+minnumber+'万元',
									type: 'warning'
								});
								return false
							}
							that.$post('addBudgetPlan', {
								id: !that.$route.query.id ? 0 : that.$route.query.id,
								equIds: jsonStrs.toString(),
								useproPrice: that.tableDatanumber,
								useproUserId: that.userId,
								useproShixunContent: that.ruleForm.poName,
								useproSign: types,
								useproBudget: 1
							}).then((res) => {
								that.$message({
									message: '添加成功',
									type: 'success'
								});
								that.$router.go(-1)
							})
						} else {
							this.$message({
								message: '请先添加设备',
								type: 'warning'
							});
						}
					} else {
						this.$message({
							message: '请完善必填信息',
							type: 'warning'
						});
					}
				});


			},
			deletetabledata(props) {
				let that = this
				that.$confirm('此操作将删除该设备, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					 beforeClose(action,instance,done) {
					          if (action == "confirm") {
					            instance.$refs.confirm.$el.onclick = function (e) {
					              e = e || window.event
					              console.log(e.detail)
					              if (e.detail != 0) {
					                done()
					              }
					            }
					          } else {
					            done()
					          }
					        }
				}).then(() => {
					that.tableData.forEach((a, s) => {
						if (a.id == props.row.id) {
							that.tableData.splice(s, 1)
						}
					})
					that.numberover()
				}).catch(() => {

				});
				that.$nextTick(() => {
					that.tableData = that.tableData.filter(res => {
						return res != "undefined"
					})
					console.log(that.tableData)
					that.$set(that.tableData)
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.numbers {
		padding: 40px 0;
		font-size: 20px;
		text-align: right;
		color: #ff0808;
	}

	.formdatas {

		margin-top: 80px;
	}

	.add {
		margin: 80px 0;
		text-align: center;
	}

	.formsubmit {
		text-align: center;
	}

	.addssa {
		margin: 80px 0;
	}

	.textinput {
		margin: 20px 0;
		width: 80%;
	}
	.pagination{
		text-align: center;
		margin: 40px 0;
	}
</style>
<style>
	.dialogs .el-input-group__prepend {
		width: 16%;
	}

	.dialogs .input-with-select {
		margin: 0 0 20px 0;
		width: 80%;
	}

	.unit {
		margin-right: 20px;
	}
</style>
